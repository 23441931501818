
import { defineComponent, PropType } from 'vue'
import type { ButtonHTMLAttributes } from 'vue'

export default defineComponent ({
  name: 'VButton',
  props: {
    minWidth: {
      type: String as PropType<string>,
      require: false,
      default: 'auto',
    },
    type: {
      type: String as PropType<ButtonHTMLAttributes['type']>,
      require: false,
      default: 'button',
    },
    theme: {
      type: String as PropType<string>,
      require: false,
      default: '',
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      require: false,
      default: false,
    },
    fixWidth: {
      type: Boolean as PropType<boolean>,
      require: false,
      default: false,
    },
    marginBottom: {
      type: String as PropType<string>,
      require: false,
      default: '16',
    }
  },
  emits: ['handleClick'],
  methods: {
    handleClick() {
      this.$emit('handleClick')
    },
  },
})
