import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["type", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["vButton", {
      fixWidth: _ctx.fixWidth,
      'vButton--gray': _ctx.theme === 'gray',
      'vButton--white': _ctx.theme === 'white',
      'vButton--whiteBlue': _ctx.theme === 'whiteBlue',
    }]),
    type: _ctx.type,
    disabled: _ctx.disabled,
    style: _normalizeStyle({ marginBottom: _ctx.marginBottom + 'px', minWidth: _ctx.minWidth + 'px'}),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 14, _hoisted_1))
}